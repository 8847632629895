<template>
    <v-container class="fill-height pa-0 ma-0" fluid>
        <v-row class="fill-height w-100" no-gutters justify="start">
            <v-col class="fill-height" align-self="start" cols="12">
                <v-container class="fill-height d-flex flex-column rounded-0 pa-0" fluid>
                    <slot />

                    <v-row class="w-100" no-gutters>
                        <v-col cols="12">
                            <slot :name="currentTab" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import { useNavigationStore } from '@/store/navigation/useNavigationStore';
    import { computed } from 'vue';
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const navigationStore = useNavigationStore();
    const { isDesktop } = useIsDesktop();

    const currentTab = computed(() => {
        return navigationStore.item?.key ?? navigationStore.items[0].key;
    });
</script>
