<template>
    <v-app-bar elevation="0" color="primary" class="app-mobile-header" height="60">
        <template v-slot:prepend>
            <AppHeaderLogo class="pl-0" />
        </template>

        <div class="app-guide-locator">
            <app-page-guide-locator />
        </div>
        <app-page-guide-message-container />

        <template v-slot:append>
            <!--            <AppHeaderUserAvatar-->
            <!--                v-if="userStore.hasProfileBeenSet"-->
            <!--                :user="userStore.user"-->
            <!--                :clickable="true"-->
            <!--            />-->
            <DebugDisplay />
            <v-list-item nav class="justify-center my-10 pr-0">
                <v-btn
                    v-if="!expandMenu"
                    width="38"
                    height="38"
                    elevation="0"
                    icon
                    variant="plain"
                    @click="expandMenu = true"
                >
                    <v-icon class="px-4">mdi-menu</v-icon>
                </v-btn>
                <v-btn
                    v-else
                    elevation="0"
                    icon
                    variant="plain"
                    size="x-small"
                    height="40"
                    width="40"
                    @click.stop="expandMenu = false"
                >
                    <v-icon size="24">mdi-close</v-icon>
                </v-btn>
            </v-list-item>
        </template>
    </v-app-bar>
    <AppHeaderNavigationOverlay v-if="expandMenu" @close="expandMenu = false" />
</template>

<script setup lang="ts">
    import DebugDisplay from '@/components/header/DebugDisplay.vue';
    import { useUsersStore } from '@/store/user/store';
    import AppHeaderLogo from '@/components/header/AppHeaderLogo.vue';
    import AppPageGuideLocator from '@/components/common/app-page/AppPageGuideLocator.vue';
    import AppPageGuideMessageContainer from '@/components/common/app-page/AppPageGuideMessageContainer.vue';
    import { ref, watch } from 'vue';
    import { useRoute } from 'vue-router';
    import AppHeaderNavigationOverlay from '@/components/header/AppHeaderNavigationOverlay.vue';

    const userStore = useUsersStore();

    const expandMenu = ref(false);

    const route = useRoute();
    watch(
        () => route.name,
        () => {
            expandMenu.value = false;
        },
    );
</script>

<style scoped lang="scss">
    .app-mobile-header :deep(.v-toolbar__content) {
        padding: 8px 20px;
    }

    .app-mobile-header :deep(.v-toolbar__extension) {
        padding-top: 8px;
        padding-left: 16px;
    }

    :deep(.v-toolbar__prepend) {
        margin-inline: 4px auto;
    }

    :deep(.v-toolbar__append) {
        margin-inline: auto 0px;
    }

    .app-guide-locator {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
</style>
