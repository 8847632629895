<template>
    <!-- ml-n4 compensates in mobile the padding on the <header />, so it starts next to the left    -->
    <v-list density="compact" nav>
        <AppHeaderListItem
            class="mb-4 pa-0"
            :to="overviewRoute"
            :active="isCurrent(overviewRoute)"
            :disabled="!userStore.hasProfileBeenSet"
            icon="home"
            tooltip="Home">
        </AppHeaderListItem>
        <AppHeaderListItem
            class="mb-4 pa-0"
            :to="storyRoute"
            :active="isCurrent(storyRoute)"
            :disabled="!userStore.hasProfileBeenSet"
            icon="book"
            tooltip="Story"
        />
        <v-tooltip location="top" text="Coming soon...">
            <template v-slot:activator="{ props }">
                <span v-bind="props">
                    <v-list-item
                        v-if="showOldPlan"
                        :to="planRoute"
                        :active="isCurrent(planRoute)"
                        :disabled="true || !userStore.hasProfileBeenSet"
                    />
                </span>
            </template>
        </v-tooltip>

        <AppHeaderListItem
            class="pa-0"
            v-if="showNewPlan"
            :to="challengePathRoute"
            :active="isChallengePath"
            :disabled="!userStore.hasProfileBeenSet"
            icon="road"
            tooltip="Challenge Pathway"
        />

        <AppHeaderListItem v-if="showGP" class="pa-0" icon="tree" tooltip="Growth Path"/>
    </v-list>
</template>

<script setup lang="ts">
    import { useUsersStore } from '@/store/user/store';
    import {
        challengePathRoute,
        type DeclaredRoute,
        overviewRoute,
        planRoute,
        storyRoute,
    } from '@/router/routes';
    import { useRoute } from 'vue-router';
    import { computed, ref } from 'vue';
    import { RoutesName } from '@/router/routesName';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import AppHeaderListItem from '@/components/header/AppHeaderListItem.vue';

    const route = useRoute();

    const { isDesktop } = useIsDesktop();

    const isCurrent = (r: DeclaredRoute) => {
        return r.name === route.name;
    };
    const isCurrentRouteName = (routeName: string) => {
        return routeName === route.name;
    };

    const userStore = useUsersStore();

    const showNewPlan = userStore.hasCPFeature;
    const showOldPlan = !showNewPlan;
    const showGP = false;

    const isChallengePath = computed(() => {
        return (
            isCurrent(challengePathRoute) ||
            isCurrentRouteName(RoutesName.SingleChallengeStart) ||
            isCurrentRouteName(RoutesName.SingleChallengeUpdateProgress) ||
            isCurrentRouteName(RoutesName.SingleChallengeSequencing)
        );
    });
</script>

<style scoped lang="scss">
    .btn-navigation {
        opacity: 0.2;
    }

    :deep(.v-list-item-title) {
        font-size: 18px;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.045px;
    }
</style>
