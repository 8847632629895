<template>
    <v-container class="story-card" variant="flat" fluid :class="cssClass">
        <v-card
            class="story-card__header pa-0"
            :color="color"
            variant="flat"
            :class="color ? '' : 'bg-none'"
        >
            <div v-if="hasSlot('image')" class="story-card__header__image pa-0">
                <slot name="image" />
            </div>
            <div v-if="hasSlot('title')" class="text-heading-h1 w-100 story-card__header__title">
                <slot name="title" />
            </div>
            <div
                v-if="hasSlot('description')"
                class="text-paragraph-md text-left w-100 d-print-none story-card__header__description no-hover"
            >
                <slot name="description" />
            </div>
        </v-card>
        <div class="story-card__content pa-0">
            <slot name="content" />
        </div>

        <slot />
    </v-container>
</template>

<script setup lang="ts">
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import { computed, useSlots } from 'vue';

    const { responsiveClass } = useIsDesktop();

    const props = defineProps<{
        color?: string;
        id: string;
    }>();

    const cssClass = computed(() => {
        return {
            [responsiveClass.value]: true,
            [props.id]: true,
        };
    });

    const slots = useSlots();
    const hasSlot = (name: string) => {
        return !!slots[name];
    };
</script>

<style scoped lang="scss">
    .what-i-offer {
        --section-background: rgb(var(--v-theme-secondary-blue-light));
    }

    .who-i-am {
        --section-background: rgb(var(--v-theme-secondary-purple-light));
    }

    .what-i-am-working-on {
        --section-background: rgb(var(--v-theme-secondary-pink-light));
    }

    .story-uvp {
        --section-background: background: linear-gradient(94.29deg, #ffc32b 0%, #fb8625 100%) !important;
    }

    .story-card {
        display: flex;
        width: 100%;
        margin: 0;
        background: var(--section-background);

        &.desktop {
            padding: 48px 64px;
            border-radius: 30px;
            gap: 64px;
            max-width: 1216px;

            .story-card__header {
                min-width: 303px;
                width: 303px;
            }

            .story-card__header__image {
                margin-bottom: 40px;
            }

            .story-card__header__description {
                margin-top: 8px;

                &:hover {
                    color: inherit;
                    opacity: 1;
                    text-decoration: none;
                }
            }
        }

        &.mobile {
            padding: 32px 20px;
            border-radius: 16px;
            flex-direction: column;
            gap: 40px;

            .story-card__header {
                min-width: 100%;
                width: 100%;
            }

            .story-card__header__image {
                margin-bottom: 20px;
            }

            .story-card__header__description {
                margin-top: 20px;
            }
        }
    }
</style>
