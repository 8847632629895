<template>
    <app-page-tabs>
        <app-page-header>
            <template #title>
                <span v-if="challenge">
                    Challenge pathway:&nbsp;
                    <span class="font-weight-600">{{ challenge.description }}</span></span
                >
                <span v-else>Challenge pathway</span>
            </template>
        </app-page-header>

        <template #tutorial>
            <ChallengePathTutorial @done="navigationStore.setDefault" />
        </template>

        <template #select-challenge>
            <ChallengePathGuide />
            <ChallengePathSelectChallengeTab />
        </template>

        <template #activities>
            <SingleChallengeNewView />
        </template>

        <template #approach>
            <SingleChallengeSequencingView />
        </template>

        <template #progress>
            <SingleChallengeUpdateProgressView />
        </template>

        <app-page-tabs-footer v-if="navigationStore.item?.footer" />

        <PageActivity :area="UserActivityArea.ChallengePath" />
    </app-page-tabs>
</template>

<script setup lang="ts">
    import { computed, onBeforeUnmount, onMounted } from 'vue';

    import { useCanvasStore } from '@/store/canvas/store';
    import { useChallengePathStore } from '@/store/challenge-path/challengePathStore';

    import { UserActivityArea } from '@/api/types/userActivity';
    import PageActivity from '@/components/common/PageActivity.vue';
    import ChallengePathTutorial from '@/components/pathway/ChallengePathTutorial.vue';
    import { useActivityStore } from '@/store/activity/store';
    import ChallengePathGuide from '@/components/pathway/ChallengePathGuide.vue';
    import { useNavigationStore } from '@/store/navigation/useNavigationStore';
    import ChallengePathSelectChallengeTab from '@/views/pathways/ChallengePathSelectChallengeTab.vue';
    import SingleChallengeNewView from '@/views/pathways/SingleChallengeNewView.vue';
    import SingleChallengeSequencingView from '@/views/pathways/SingleChallengeSequencingView.vue';
    import { makeNavigationTabs } from '@/store/navigation/utils';
    import SingleChallengeUpdateProgressView from '@/views/pathways/SingleChallengeUpdateProgressView.vue';
    import { isNew } from '@/store/challenge-path/util';
    import { storyRoute } from '@/router/routes';
    import { makeNext, makePrevious } from '@/components/common/app-page/types';
    import AppPageTabsFooter from '@/components/common/app-page/AppPageTabsFooter.vue';
    import { useSingleChallengePathStore } from '@/store/challenge-path/singleChallengeStore';

    const challenge = computed(() => {
        return navigationStore.ctx?.item ?? null;
    });

    const singleChallengePathStore = useSingleChallengePathStore();
    const items = makeNavigationTabs([
        {
            title: 'Tutorial',
            key: 'tutorial',
            footer: false,
        },
        {
            title: 'Select challenge',
            key: 'select-challenge',
            default: true,
            onSelect: () => {
                navigationStore.clearCtx();
            },
            footer: {
                prev: makePrevious({
                    to: storyRoute,
                }),
            },
        },
        {
            title: 'Activities',
            key: 'activities',
            disabled: () => !challenge.value,
            footer: {
                prev: {},
                next: makeNext({
                    disabled: () => singleChallengePathStore.selected.length < 3,
                    tooltip: 'Please select at least 3 actions',
                }),
            },
        },
        {
            title: 'Approach',
            key: 'approach',
            disabled: () => {
                return !challenge.value || singleChallengePathStore.selected.length < 3;
            },
            // The footer in this view is handled by the SingleChallengeSequencingView
            // At the moment the cb functions are too complex to handle here
            footer: false,
        },
        {
            title: 'Progress',
            key: 'progress',
            disabled: () => {
                return !challenge.value || (!!challenge.value && isNew(challenge.value));
            },
            footer: {
                prev: {},
            },
        },
    ]);

    const navigationStore = useNavigationStore();
    navigationStore.init(items);

    const { setTutorial } = useNavigationStore();

    const store = useChallengePathStore();
    const canvasStore = useCanvasStore();
    const activityStore = useActivityStore();

    await canvasStore.load();
    await store.load();

    onMounted(async () => {
        const noChallengeWithSuggestions = store.activeChallenges.every(
            (challenge) => !challenge.suggested_actions.length,
        );
        const noChallengesStarted =
            store.notStartedChallenges.length &&
            !store.inProgressChallenges.length &&
            !store.completedChallenges.length;
        if (!store.hasActiveChallenges || (noChallengesStarted && noChallengeWithSuggestions)) {
            setTutorial();
        }

        await activityStore.addEntryActivity(UserActivityArea.ChallengePath);
    });

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.ChallengePath);
    });
</script>
