import { defineStore } from 'pinia';
import { makeDataState } from '@/store/common/dataState';
import { useAppErrorStore } from '@/store/appErrorStore';
import type { SingleChallengeStoreState } from '@/store/challenge-path/types';
import { type CanvasCurrentChallenge } from '@/api/types/canvas/currentChallenge';
import { verify } from '@/store/verify';
import { useCanvasStore } from '@/store/canvas/store';
import {
    GenerateSuggestionsTimeoutError,
    pollChallenge,
} from '@/services/plan/current/challenge/pollChallenge';
import {
    ActionType,
    type ChallengeActionNewForm,
    type CurrentChallengeAction,
    CurrentChallengeActionState,
    type SuggestedChallengeAction,
    SuggestedChallengeActionState,
} from '@/api/types/plan/currentChallengeAction';
import { fetchPathwayChallenge } from '@/services/challenge-path/service';
import { useLoadingStore } from '@/store/loadingStore';
import {
    createCanvasCurrentChallengeActionFeedback,
    createPathwayChallengeNewSuggestions,
    createPathwayChallengeSuggestions,
} from '@/services/plan/current/actions/service';
import {
    isCompleted,
    isCurrentChallengeAction,
    isInProgress,
    isNew,
    isSuggestedAction,
    makeNewActivity,
    validateChallenge,
} from '@/store/challenge-path/util';
import {
    activatePathwayChallenge,
    deactivatePathwayChallenge,
    startPathwayChallenge,
    updatePathwayChallenge,
} from '@/services/plan/current/challenge/service';
import {
    type EditableCurrentChallengeActionFeedback,
    makeEmptyCurrentChallengeActionFeedback,
} from '@/store/challenge-path/feedback/util';
import { cloneDeep } from 'lodash';
import type {
    StartChallengeCreateActionData,
    UpdateChallengeActionData,
} from '@/services/plan/current/challenge/api';

import * as Sentry from '@sentry/vue';
import { useLearnedExperiencesStore } from '@/store/learned-experiences/store';

/**
 * We assume that activities will be generated within 90 seconds. If not, we will show an error message,
 * and allow the user to try again. On the retry, we will show the loading screen again.
 * However, if the activities are generated within 90 seconds, we will show the activities.
 *
 * It is possible that activities from the previous run are shown, but this is acceptable.
 * Even maybe, the user see the double activities, but this is acceptable too.
 */
const POLLING_SUGGESTED_ACTIONS_TIMEOUT = 90 * 1000;

export type SelectableChallengeAction =
    | CurrentChallengeAction
    | ChallengeActionNewForm
    | SuggestedChallengeAction;
export const useSingleChallengePathStore = defineStore({
    id: 'single-challenge-path',
    state: (): SingleChallengeStoreState => ({
        current: null,
        _challenge: null,
        selected: [],
        customActivities: [],
        feedback: null,
        _timeoutError: false,
        ...makeDataState(),
    }),
    getters: {
        challenge(state): CanvasCurrentChallenge | null {
            return state._challenge;
        },
        isNew(state): boolean {
            return !!state._challenge && isNew(state._challenge);
        },
        isInProgress(state): boolean {
            return !!state._challenge && isInProgress(state._challenge);
        },
        isCompleted(state): boolean {
            return !!state._challenge && isCompleted(state._challenge);
        },
        daysLeft(state): number | null {
            // Filter out null or invalid dueDates and map to date objects
            const validDates = this.actions
                .map((action) => action.due_date)
                .filter((date) => !!date)
                .map((date) => new Date(date));

            if (validDates.length === 0) {
                return null;
            }

            // Find the latest date (last due date)
            const lastDay = validDates.sort((a, b) => a.getTime() - b.getTime())[
                validDates.length - 1
            ];

            // Calculate days left
            const today = new Date();

            const timeDiff = lastDay.getTime() - today.getTime();
            return Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
        },
        /**
         * Suggested actions + User created actions while in new state
         */
        selectableActions(state): SelectableChallengeAction[] {
            const suggestedActions = state._challenge?.suggested_actions ?? [];

            // Filter suggestedActions where state is 'suggested'
            const filteredSuggestedActions = suggestedActions.filter(
                (action) => action.state === SuggestedChallengeActionState.Suggested,
            );
            return [...filteredSuggestedActions, ...this.customActivities];
        },
        inProgressSelectableActions(state): (CurrentChallengeAction | SelectableChallengeAction)[] {
            return [...this.actions, ...this.selectableActions];
        },
        actions(state): CurrentChallengeAction[] {
            const actions = state._challenge?.actions ?? [];
            const _byState = (a: CurrentChallengeAction, b: CurrentChallengeAction) => {
                if (a.state === b.state) {
                    return a.id - b.id;
                }
                return a.state === CurrentChallengeActionState.Completed ? -1 : 1;
            };

            return [...actions.sort(_byState)];
        },
        progress(state): number {
            const actions = state._challenge?.actions ?? [];
            const completedActions = actions.filter(
                (action) => action.state === CurrentChallengeActionState.Completed,
            );
            return (completedActions.length / actions.length) * 100;
        },
        isAddingNew(): boolean {
            return !!this.current;
        },
    },
    actions: {
        async loadNew(challengeId: number): Promise<void> {
            this.$reset();

            console.info('Loading challenge path (new)...');
            await useAppErrorStore().catchErrors(async () => {
                const { canvasId, accessToken } = await useCanvasStore().makeContext();
                const maybeChallenge = await fetchPathwayChallenge(
                    canvasId,
                    challengeId,
                    accessToken,
                );
                const challenge = verify(maybeChallenge, 'Challenge not found');
                validateChallenge(challenge);
                verify(isNew(challenge), 'Challenge already started');

                if (challenge.suggested_actions.length === 0) {
                    console.info('Challenge not started');

                    useLoadingStore().setLoadingText(
                        'Setting up this challenge for you.',
                        'Please stay on this page. This can take up to a minute...',
                    );

                    await createPathwayChallengeSuggestions(
                        challenge.canvas_id,
                        challenge.id,
                        accessToken,
                    );

                    try {
                        const challengeWithSuggestedActions = await pollChallenge(
                            challenge.canvas_id,
                            challenge.id,
                            (c) => c.suggested_actions.length > 0,
                            { pollTimeout: POLLING_SUGGESTED_ACTIONS_TIMEOUT },
                            accessToken,
                        );
                        this._challenge = this._makeChallenge(challengeWithSuggestedActions);
                    } catch (error) {
                        if (error instanceof GenerateSuggestionsTimeoutError) {
                            Sentry.captureException(error);
                            this._timeoutError = true;
                        } else {
                            throw error;
                        }
                    }
                } else {
                    this._challenge = this._makeChallenge(challenge);
                }
            });
        },
        async loadMore(challengeId: number): Promise<void> {
            this.$reset();

            console.info('Loading challenge path (more)...');
            await useAppErrorStore().catchErrors(async () => {
                const { canvasId, accessToken } = await useCanvasStore().makeContext();
                const maybeChallenge = await fetchPathwayChallenge(
                    canvasId,
                    challengeId,
                    accessToken,
                );
                const challenge = verify(maybeChallenge, 'Challenge not found');
                validateChallenge(challenge);

                const priorSuggestedActionsLength = challenge.suggested_actions.length

                useLoadingStore().setLoadingText(
                    'Finding some new activities for you.',
                    'Please stay on this page. This can take up to a minute...',
                );

                await createPathwayChallengeNewSuggestions(
                    challenge.canvas_id,
                    challenge.id,
                    accessToken,
                );

                try {
                    const challengeWithSuggestedActions = await pollChallenge(
                        challenge.canvas_id,
                        challenge.id,
                        (c) => c.suggested_actions.length > priorSuggestedActionsLength,
                        { pollTimeout: POLLING_SUGGESTED_ACTIONS_TIMEOUT },
                        accessToken,
                    );
                    this._challenge = this._makeChallenge(challengeWithSuggestedActions);
                    this.selected = cloneDeep(this._challenge.actions);
                } catch (error) {
                    if (error instanceof GenerateSuggestionsTimeoutError) {
                        Sentry.captureException(error);
                        this._timeoutError = true;
                    } else {
                        throw error;
                    }
                }
            });
        },
        async loadEdit(challengeId: number): Promise<void> {
            console.info('Loading challenge path (edit)...');

            await useAppErrorStore().catchErrors(async () => {
                const { canvasId, accessToken } = await useCanvasStore().makeContext();
                const maybeChallenge = await fetchPathwayChallenge(
                    canvasId,
                    challengeId,
                    accessToken,
                );

                const challenge = verify(maybeChallenge, 'Challenge not found');
                validateChallenge(challenge);
                verify(!isNew(challenge), 'Challenge not in progress');

                this._challenge = this._makeChallenge(challenge);
                this.selected = cloneDeep(this._challenge.actions);
            });
        },
        async syncChallenge(challengeId: number): Promise<void> {
            console.info('Loading challenge path...');

            await useAppErrorStore().catchErrors(async () => {
                const { canvasId, accessToken } = await useCanvasStore().makeContext();
                const maybeChallenge = await fetchPathwayChallenge(
                    canvasId,
                    challengeId,
                    accessToken,
                );

                const challenge = verify(maybeChallenge, 'Challenge not found');
                validateChallenge(challenge);
                this._challenge = this._makeChallenge(challenge);
                this.selected = cloneDeep(this._challenge.actions);
            });
        },
        async startChallenge(): Promise<void> {
            await useAppErrorStore().catchErrors(async () => {
                const challenge = verify(this._challenge, 'Challenge not found');
                verify(isNew(challenge), 'Challenge already started');
                verify(this.selected.length > 0, 'No actions selected');

                const { canvasId, accessToken } = await useCanvasStore().makeContext();

                const selectedActions = {
                    actions: this.selected,
                } as StartChallengeCreateActionData;
                await startPathwayChallenge(canvasId, challenge.id, selectedActions, accessToken);
            });
        },
        async updateChallenge(): Promise<void> {
            await useAppErrorStore().catchErrors(async () => {
                const challenge = verify(this._challenge, 'Challenge not found');
                verify(isInProgress(challenge), 'Challenge already started');
                verify(this.selected.length > 0, 'No actions selected');

                const { canvasId, accessToken } = await useCanvasStore().makeContext();

                const selectedActions = {
                    actions: this.selected,
                } as UpdateChallengeActionData;
                await updatePathwayChallenge(canvasId, challenge.id, selectedActions, accessToken);
            });
        },
        addActivity(): void {
            this.current = makeNewActivity();
        },
        cancelNew(): void {
            this.current = null;
        },
        saveNew(): void {
            const value = verify(this.current, 'No activity to save');
            const newActivity = cloneDeep(value);
            this.customActivities.push(newActivity);
            this.toggleSelected(newActivity);
            this.current = null;
        },
        toggleSelected(action: SelectableChallengeAction) {
            const found = this.selected.find((a) => {
                if (isCurrentChallengeAction(action) || isSuggestedAction(action)) {
                    if (a.type === action.type) {
                        if (isCurrentChallengeAction(a) || isSuggestedAction(a)) {
                            return a.id === action.id;
                        } else {
                            return a.title === action.title && a.description === action.description;
                        }
                    }
                } else {
                    return a.title === action.title && a.description === action.description;
                }
            });

            if (found) {
                const index = this.selected.indexOf(found);
                this.selected.splice(index, 1);
            } else {
                this.selected.push(action);
            }
        },
        moveActionUp(index: number) {
            const action = this.selected[index];
            if (action) {
                this.selected.splice(index, 1);
                this.selected.splice(index - 1, 0, action);
            }
        },
        moveActionDown(index: number) {
            const action = this.selected[index];
            if (action) {
                this.selected.splice(index, 1);
                this.selected.splice(index + 1, 0, action);
            }
        },
        onMarkAsCompleted(action: CurrentChallengeAction) {
            this.feedback = makeEmptyCurrentChallengeActionFeedback(action);
        },
        async saveActivityFeedback(feedback: EditableCurrentChallengeActionFeedback) {
            const { canvasId, accessToken } = await useCanvasStore().makeContext();
            const challengeId = verify(this._challenge?.id, 'Challenge not found');
            const _createdFeedback = await createCanvasCurrentChallengeActionFeedback(
                canvasId,
                challengeId,
                feedback,
                accessToken,
            );

            await useSingleChallengePathStore().syncChallenge(challengeId);

            if (feedback.add_to_learned_experience) {
                // Reload learned experiences after being added
                await useLearnedExperiencesStore().sync();
            }
        },
        _makeChallenge(challenge: CanvasCurrentChallenge): CanvasCurrentChallenge {
            // Update actions and suggested actions using map and object spread

            return {
                ...challenge,
                actions: challenge.actions.map((action) => ({
                    ...action,
                    type: action.type || ActionType.UserCreated, // Set default if not already set
                })),
                suggested_actions: challenge.suggested_actions.map(
                    (action: SuggestedChallengeAction) => {
                        return {
                            ...action,
                            type: ActionType.Suggested,
                        };
                    },
                ),
            };
        },
        async deactivateChallenge() {
            await useAppErrorStore().catchErrors(async () => {
                const { canvasId, accessToken } = await useCanvasStore().makeContext();

                const challenge = verify(this._challenge, 'Challenge not found');
                const challengeId = verify(challenge?.id, 'Challenge id not found');
                await deactivatePathwayChallenge(canvasId, challengeId, accessToken);

                await useSingleChallengePathStore().syncChallenge(challengeId);
            });
        },
    },
});
