<template>
    <v-card
        variant="flat"
        class="fill-height app-guide-modal-card text-neutral-black text-center"
        :class="responsiveClass"
    >
        <v-container class="app-guide-modal-container fill-height pa-0">
            <div no-gutters class="app-guide-modal-content">
                <app-guide-avatar size="96" :animate="animateAlex" class="" />

                <div>
                    <div class="text-heading-h1 mb-6 w-100">
                        <slot name="title" :step="step" />
                    </div>

                    <div class="text-paragraph-lg w-100">
                        <slot :step="step" />
                    </div>
                </div>

                <div class="app-guide-modal-navigation">
                    <app-button-icon
                        :disabled="step === 1"
                        @click="previous"
                        v-show="step >= 1"
                        size="x-small"
                        color="primary"
                        icon="mdi-arrow-left"
                        variant="flat"
                        class="navigation-previous"
                    />

                    <div class="py-6 px-0 app-guide-modal-navigation-circles" v-if="steps > 1">
                        <div
                            v-if="steps > 1"
                            class="navigation-circle mx-2"
                            v-for="s in range(0, steps)"
                            :key="s"
                            :class="{
                                'navigation-circle-done': s + 1 <= step,
                            }"
                            @click="step = s + 1"
                        />

                        <div
                            v-if="hasSkip && !isLast"
                            class="w-100 mt-2 text-custom-neutral-black text-paragraph-sm font-weight-600"
                            style="position: absolute; left: 0"
                        >
                            <span @click="close" class="cursor-pointer">Skip</span>
                        </div>
                    </div>
                    <v-spacer v-else />

                    <slot
                        v-if="hasSlot('actions')"
                        name="actions"
                        :step="step"
                        :next="next"
                        :previous="previous"
                        :is-last="isLast"
                        :close="close"
                    />
                    <app-button
                        v-else-if="isLast"
                        @click="close"
                        color="primary"
                        variant="flat"
                        append-icon="mdi-arrow-right"
                        weight="bold"
                        :class="steps > 1 ? 'navigation-next' : ''"
                        :disabled="disabledNext"
                        >{{ actionText }}
                    </app-button>
                    <app-button-icon
                        v-else
                        @click="next"
                        size="x-small"
                        color="primary"
                        icon="mdi-arrow-right"
                        :class="steps > 1 ? 'navigation-next' : ''"
                        :disabled="disabledNext"
                        variant="flat"
                    />
                </div>
            </div>
        </v-container>
    </v-card>
</template>

<script setup lang="ts">
    import { computed, onBeforeUnmount, ref, useSlots, watch } from 'vue';
    import { range } from 'lodash';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import { useRouter } from 'vue-router';

    const router = useRouter();

    const step = ref(1);

    watch(
        () => step.value,
        (value) => {
            emit('change-step', value);
        },
    );

    const { responsiveClass } = useIsDesktop();

    const props = withDefaults(
        defineProps<{
            variant?: 'short' | 'long';
            sectionName?: string;
            actionText: string;
            steps?: number;
            animateAlex?: boolean;
            disabledNext?: boolean;
        }>(),
        {
            actionText: 'Close',
            variant: 'short',
            steps: 1,
            disabledNext: false,
        },
    );

    const { isDesktop } = useIsDesktop();

    const emit = defineEmits(['change-step', 'done']);

    const close = () => {
        emit('done');
    };

    const hasSkip = computed(() => {
        return props.steps > 1;
    });

    const previous = () => {
        step.value -= 1;
    };

    const next = () => {
        step.value += 1;
    };

    const isLast = computed(() => {
        return step.value === props.steps;
    });

    const slots = useSlots();
    const hasSlot = (name: string) => {
        return !!slots[name];
    };
</script>

<style scoped lang="scss">
    .app-guide-modal-card {
        &.desktop {
            padding: 64px;
        }

        &.mobile {
            padding: 64px 16px;
        }

        justify-content: center;
        align-items: center;
        gap: 64px;
        flex: 1 0 0;
        align-self: stretch;

        background: none;
        --max-width: 660px;

        &.desktop {
            .app-guide-modal-card-title {
                max-width: var(--max-width);
            }

            .app-guide-modal-card-content {
                max-width: var(--max-width);
            }

            .app-guide-modal-navigation {
                max-width: var(--max-width);
            }
        }

        &.mobile {
            .app-guide-modal-card-title {
                width: 100%;
            }

            .app-guide-modal-card-content {
                width: 100%;
            }
        }
    }

    .app-guide-modal-container {
        align-items: center;
        gap: 40px;
        display: flex;
        max-width: var(--max-width);
        flex-direction: column;
        flex: 1 0 0;
        align-self: stretch;
    }

    .app-guide-modal-navigation-circles {
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
    }

    .app-guide-modal-content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;
        flex: 1 0 0;
        display: flex;
        align-self: stretch;
        padding: 0;

        :deep(p) {
            margin-bottom: 16px;
            padding-left: 0;
            padding-right: 0;
        }
    }

    .app-guide-modal-navigation {
        height: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        position: relative;

        .navigation-previous {
            position: absolute;
            left: 0;
        }

        .navigation-next {
            position: absolute;
            right: 0;
        }

        .navigation-circle {
            cursor: pointer;
            width: 15px; /* The width of the circle */
            height: 15px; /* The height of the circle */
            background-color: rgb(var(--v-theme-custom-neutral-black));
            opacity: 0.3;
            border-radius: 50%; /* Makes it a circle */
            display: inline-block; /* Ensures the circle behaves like a block */

            &.navigation-circle-done {
                opacity: unset;
            }
        }
    }
</style>
