<template>
    <!--
        TODO: The error should be renamed to notifications
        It used to be part of the layout, but since it is implemented with a v-snackbar
        and its position is absolute, it could be taken out of this component.
     -->
    <slot name="errors" />

    <v-footer
        :app="true"
        class="app-page-footer pa-0"
        fluid
        :class="cssClasses"
    >
        <v-row no-gutters class="app-page-footer-content bg-white" v-if="hasActionsSlot">
            <v-col class="" cols="12">
                <slot name="actions" />
            </v-col>
        </v-row>
    </v-footer>
</template>
<script setup lang="ts">
    import { useDisplay } from 'vuetify';
    import { computed, useSlots } from 'vue';
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const { mdAndUp } = useDisplay();

    const props = defineProps<{
        noApp?: boolean;
        md?: string | number | undefined;
        lg?: string | number | undefined;
        xl?: string | number | undefined;
    }>();

    const { responsiveClass } = useIsDesktop();

    const slots = useSlots();

    const hasActionsSlot = computed(() => {
        return !!slots['actions'];
    });

    const cssClasses = computed(() => {
        return {
            [responsiveClass.value]: true,
        };
    });
</script>

<style scoped lang="scss">
    .desktop {
        --footer-padding-left: 16px !important;
        --footer-padding-right: 16px !important;
    }

    .mobile {
        --footer-padding-left: 20px !important;
        --footer-padding-right: 20px !important;
    }

    //.app-page-footer {
    //display: flex;
    //flex-direction: column;
    //justify-content: center;
    //align-items: center;

    .app-page-footer {
        border-top: 1px solid rgb(var(--v-theme-outline)) !important;

        &.desktop {
            // The background color of the <v-app-bar /> and the padding gives the impression that the
            // scrolling happens withing a the app frame (keeping a primary color background in the top and right)

            //background-color: rgb(var(--v-theme-primary));
            background: none;

            padding-bottom: 4px !important;
            width: calc(100% - 54px - 4px) !important;

            .app-page-footer-content {
                border-radius: 0 0 8px 8px;
            }

            //footer {
            //
            //    bottom: 0 !important;
            //    border-top: 1px solid rgb(var(--v-theme-outline));
            //}
        }

        .app-page-footer-content {
            padding: 8px var(--footer-padding-right) 8px var(--footer-padding-left);
            //max-width: 1382px;
            margin: 0 auto; // This ensures horizontal centering
        }
    }
</style>
