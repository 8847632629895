<template>
    <div v-if="isDesktop" class="app-full-page-guide-locator d-print-none">
        <app-page-guide-locator />
    </div>
    <app-page-guide-message-container v-if="isDesktop" class="d-print-none" />

    <v-container fluid class="fill-height app-main-layout__scrollable" :class="cssClasses">
        <v-container
            fluid
            class="fill-height app-main-layout__scrollable__content"
            :class="isCurrent(storyRoute) ? 'story-page' : 'other-page'"
        >
            <AppRouterView />
        </v-container>
    </v-container>
</template>

<script setup lang="ts">
    import { useRoute } from 'vue-router';
    import { computed } from 'vue';
    import { type DeclaredRoute, storyRoute } from '@/router/routes';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import AppPageGuideMessageContainer from '@/components/common/app-page/AppPageGuideMessageContainer.vue';
    import AppRouterView from '@/AppRouterView.vue';

    const { responsiveClass, isDesktop } = useIsDesktop();

    const route = useRoute();

    const isCurrent = (r: DeclaredRoute) => {
        return r.name === route.name;
    };

    const cssClasses = computed(() => ({
        [responsiveClass.value]: true,
    }));
</script>

<style scoped lang="scss">
    .app-full-page-guide-locator {
        position: absolute;
        right: 16px;
        top: 16px;
    }

    .app-main-layout__scrollable {
        &.desktop {
            padding: 64px 40px;
            overflow-y: scroll;
            /* Hide scrollbar */
            scrollbar-width: none; /* For Firefox */
            -ms-overflow-style: none; /* For IE and Edge */

            .app-main-layout__scrollable__content {
                &.other-page {
                    max-width: 1382px;
                }

                &.story-page {
                    max-width: 1200px;
                }
            }
        }

        &.mobile {
            padding: 0px;
            .app-main-layout__scrollable__content {
                &.other-page {
                    padding: 0 16px;
                }

                &.story-page {
                    padding: 0;
                }
            }
        }
    }
</style>
