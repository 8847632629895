<template>
    <v-navigation-drawer
        :mobile="false"
        elevation="0"
        class="py-8 app-desktop-header align-center border-none d-print-none"
        rail
        rail-width="54"
    >
        <v-list-item nav>
            <AppHeaderLogo />
        </v-list-item>

        <v-list-item nav class="justify-center my-10 py-0">
            <v-btn
                width="38"
                height="38"
                elevation="0"
                icon
                variant="plain"
                @click="expandMenu = true"
            >
                <v-icon class="px-4">mdi-menu</v-icon>
            </v-btn>
        </v-list-item>

        <AppHeaderNavigation v-if="userStore.hasProfileBeenSet" />

        <template v-slot:append>
            <v-list density="compact" nav class="pa-0">
                <v-list-item nav>
                    <template #prepend>
                        <DebugDisplay class="ml-4" />
                    </template>
                </v-list-item>

                <AppHeaderUserAvatar
                    v-if="userStore.hasProfileBeenSet"
                    :user="userStore.user"
                    :clickable="true"
                />

                <AppHeaderSupportMenu />
            </v-list>
        </template>
    </v-navigation-drawer>
    <AppHeaderNavigationOverlay v-if="expandMenu" @close="expandMenu = false" />
</template>

<script setup lang="ts">
    import DebugDisplay from '@/components/header/DebugDisplay.vue';
    import { useUsersStore } from '@/store/user/store';
    import AppHeaderUserAvatar from '@/components/header/AppHeaderUserAvatarMenu.vue';
    import AppHeaderLogo from '@/components/header/AppHeaderLogo.vue';
    import AppHeaderNavigation from '@/components/header/AppHeaderNavigation.vue';
    import { ref, watch } from 'vue';
    import AppHeaderNavigationOverlay from '@/components/header/AppHeaderNavigationOverlay.vue';
    import { useRoute } from 'vue-router';
    import AppHeaderSupportMenu from '@/components/header/AppHeaderSupportMenu.vue';

    const userStore = useUsersStore();

    const expandMenu = ref(false);

    const route = useRoute();
    watch(
        () => route.name,
        () => {
            expandMenu.value = false;
        },
    );
</script>

<style scoped lang="scss">
    .app-desktop-header {
        background: none;
        padding-top: 32px;
        padding-bottom: 20px;
    }

    :deep(.v-list-item__overlay) {
        display: none;
    }
</style>
