<template>
    <v-container fluid class="bg-white ma-0 d-print-only pa-0 story-heading-print-only">
        <!--
               Note: This header in only printed when using @media print
               Ideally, we should override only the print styles in the component,
               but given both components are not very similar, a new layout is implemented.
           -->
        <v-row
            no-gutters
            class="mx-0 mb-1 w-100 d-print-only-flex align-center story-heading-print-only--container"
            v-if="activityStore.lastCompleted"
        >
            <v-col cols="7">
                <div class="text-heading-h1 story-printing-heading my-0">
                    {{ userStore.displayName ?? 'Your' }}'s story
                </div>
            </v-col>
            <v-col>
                <div class="d-flex justify-end ga-4 mt-4">
                    <div class="">
                        <span class="font-weight-regular text-paragraph-sm text-custom-neutral-grey-700">
                            Last activity
                        </span>
                        <div class="text-paragraph-sm font-weight-600 w-100">
                            {{ lastCompletedDate }}
                        </div>
                    </div>
                    <div>
                        <span class="font-weight-regular text-paragraph-sm text-custom-neutral-grey-700">
                            Last completed
                        </span>
                        <div class="text-paragraph-sm font-weight-600 w-100">
                            {{ lastCompletedActivityLabel }}
                        </div>
                    </div>
                    <div class="ml-4 mr-8">
                        <LogoCircle height="73" width="73" :eager="true" />
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { useActivityStore } from '@/store/activity/store';
    import { useUsersStore } from '@/store/user/store';
    import LogoCircle from '@/components/header/PrintLogoCircle.vue';
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const activityStore = useActivityStore();
    const userStore = useUsersStore();

    const lastCompletedDate = computed(() => {
        return activityStore.lastCompletedDate;
    });

    const lastCompletedActivityLabel = computed(() => {
        return activityStore.lastCompletedActivityLabel;
    });

    const { isDesktop } = useIsDesktop();
</script>
