import { computed, ref } from 'vue';
import type { NavigationTabItem } from '@/components/common/app-page/types';
import { defineStore } from 'pinia';
import { verify } from '@/store/verify';

export const useNavigationStore = defineStore('navigation-store', () => {
    const items = ref<NavigationTabItem[]>([]);
    const item = ref<NavigationTabItem | null>(null);
    const ctx = ref<any | null>(null);

    const clearCtx = () => {
        ctx.value = null;
    };

    const init = (newItems: NavigationTabItem[]): void => {
        items.value = newItems;

        const selected = newItems.find((i) => i.default);
        setCurrent(selected || newItems[0] || null);
    };

    const setCurrent = (newItem: NavigationTabItem | null): void => {
        item.value = newItem;

        if (item.value?.onSelect) {
            item.value?.onSelect();
        }
    };

    const setTabByKey = (name: string, context?: any): void => {
        const found = items.value.find((i) => i.key === name);

        if (context) {
            ctx.value = context;
        }

        setCurrent(verify(found, 'Item not found'));
    };

    const setPrevious = (): void => {
        const current = verify(item.value, 'Current tab must be set');
        const previousTab = items.value.find((i) => {
            return i.order === current.order - 1;
        });
        setCurrent(verify(previousTab, 'Previous item not found'));
    };

    const setNext = (): void => {
        const current = verify(item.value, 'Current tab must be set');
        const nextTab = items.value.find((i) => {
            return i.order === current.order + 1;
        });
        setCurrent(verify(nextTab, 'Next item not found'));
    };

    const setDefault = (): void => {
        const defaultItem = items.value.find((i) => i.default);
        setCurrent(verify(defaultItem, 'Default item not found'));
    };

    const setTutorial = () => {
        const tutorialItem = items.value.find((i) => i.key === 'tutorial' || i.key === 't');
        setCurrent(verify(tutorialItem, 'Tutorial item not found'));
    };

    const setActivity = (): void => {
        debugger;
        const tutorialItem = items.value.find((i) => i.key === 'activity');
        setCurrent(verify(tutorialItem, 'Tutorial item not found'));
    };

    //
    const isCurrent = (key: string): boolean => {
        return item.value?.key === key;
    };

    const isTutorial = computed(() => {
        return isCurrent('tutorial');
    });

    const isActivity = computed(() => {
        return isCurrent('activity');
    });

    return {
        items,
        item,
        ctx,
        clearCtx,
        init,

        isTutorial,
        isActivity,
        isCurrent,

        setDefault,
        setCurrent,
        setTutorial,
        setActivity,
        setTabByKey,
        setPrevious,
        setNext,
    };
});
