<template>
    <StoryCard :id="props.section.id" :color="backgroundColor">
        <template #image>
            <img :src="props.section.image" class="w-50 h-100" />
        </template>

        <template #title>
            {{ props.section.title }}
        </template>

        <template #description>
            {{ props.section.description }}
        </template>

        <template #content>
            <StorySectionStatement
                v-if="
                    section.statement?.content &&
                    section.statement?.state === StoryStatementState.Completed
                "
                :is-feedback-updating="feedbackUpdating"
                :section="section"
                @on-feedback="onFeedback"
                class="mb-10 print-story-section-statement-container"
            />
            <StorySectionStatementInProgress
                v-else-if="
                    section.statement?.state === StoryStatementState.Progress ||
                    section.statement?.state === StoryStatementState.New
                "
                :section="section"
                class="mb-10 print-story-section-statement-container"
            />
            <!-- Note: when no statement is displayed as subtitle inside next component -->

            <StorySectionActivities class="ma-0" :items="props.section.activities" />
        </template>
    </StoryCard>
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue';
    import StorySectionStatement from '@/components/story/section/statement/StorySectionStatement.vue';
    import StorySectionActivities from '@/components/story/section/activity/StorySectionActivities.vue';
    import StorySectionStatementInProgress from '@/components/story/section/statement/StorySectionStatementInProgress.vue';
    import { useStoryStore } from '@/store/story/store';
    import { StorySectionId, type StorySectionItem } from '@/store/story/types';
    import { StoryStatementState } from '@/api/types/canvas/apiStory';
    import StoryCard from '@/components/story/section/StoryCard.vue';

    const props = defineProps<{
        section: StorySectionItem;
    }>();

    const backgroundColor = computed(() => {
        switch (props.section.id) {
            case StorySectionId.WhatIOffer:
                return 'secondary-blue-light';
            case StorySectionId.WhoIAm:
                return 'secondary-purple-light';
            case StorySectionId.WhatIAmWorkingOn:
                return 'secondary-pink-light';
            default:
                throw new Error('Invalid section type');
        }
    });

    const store = useStoryStore();

    const feedbackUpdating = ref(false);
    const onFeedback = async (value: boolean) => {
        if (!props.section.statement) {
            return;
        }

        try {
            feedbackUpdating.value = true;
            await store.saveFeedback(props.section.id, props.section.statement, value);
        } finally {
            feedbackUpdating.value = false;
        }
    };
</script>
