/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@/styles/main.scss';
import '@mdi/font/css/materialdesignicons.css';

// Composables
import { createVuetify } from 'vuetify';

import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import storyTheme, { challengePathTheme, growthPathTheme } from '@/plugins/vuetify/theme';

export const DEFAULT_THEME = 'story';
export const CHALLENGE_PATH_THEME = 'challenge-path';
export const GROWTH_PATH_THEME = 'growth-path';

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
    components: components,
    directives: directives,
    theme: {
        defaultTheme: DEFAULT_THEME,
        themes: {
            [DEFAULT_THEME]: storyTheme,
            [CHALLENGE_PATH_THEME]: challengePathTheme,
            [GROWTH_PATH_THEME]: growthPathTheme,
        },
    },
});
