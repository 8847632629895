<template>
    <v-row class="pa-0" no-gutters :class="isDesktop ? 'gap-120 px-10' : 'gap-32'">
        <v-col :cols="isDesktop ? true : 12">
            <v-img :src="storyHeadingImage" />
        </v-col>
        <v-col :cols="isDesktop ? true : 12">
            <StoryHeadingWelcomeCard />

            <v-row no-gutters class="mt-4 ma-0 w-100">
                <div class="text-paragraph-sm font-weight-600">
                    About Story
                    <app-fa-icon icon="fa-arrow-down" class="ml-4" />
                </div>
            </v-row>
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
    import { useStoryStore } from '@/store/story/store';
    import StoryHeadingWelcomeCard from '@/components/story/heading/StoryHeadingWelcomeCard.vue';
    import { useActivityStore } from '@/store/activity/store';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import storyHeadingImage from '@/assets/images/story/story-heading-image.svg';

    const store = useStoryStore();
    const activityStore = useActivityStore();

    const { isDesktop } = useIsDesktop();
</script>
