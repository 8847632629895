<template>
    <app-tutorial section-name="Memorable Experiences" action-text="Let's go" :steps="2">
        <template #title="{ step }">
            <span v-if="step === 1">Welcome to the memorable experiences activity!</span>
            <span v-else-if="step === 2">Time to reflect</span>
        </template>
        <template #default="{ step }">
            <div v-if="step === 1">
                <p>
                    This activity is about exploring what you've learned through your career - both
                    what works for you, and things you don't want to repeat!
                </p>
                <p>
                    Each job will have had highs and lows, and from those experiences, you will have
                    learned some new skills and insights about what you enjoy. By reflecting on
                    these we can guide you to things in your future that are more likely to work for
                    you.
                </p>
            </div>
            <div v-else-if="step === 2">
                <p>
                    Recall the most memorable moments at each job and what went well, or not so
                    well, and what you learned.
                </p>
                <p>
                    If you need help - ask Alex, especially if trying to understand what skills or
                    knowledge you learned from an experience.
                </p>
            </div>
        </template>
    </app-tutorial>
</template>
