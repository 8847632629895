<template>
    <router-view v-slot="{ Component }">
        <suspense timeout="0">
            <template #fallback>
                <Loader
                    class="fill-height"
                    :title="loadingStore.title"
                    :subtitle="loadingStore.subtitle"
                />
            </template>

            <template #default>
                <component :is="Component" />
            </template>
        </suspense>
    </router-view>
</template>
<script setup lang="ts">
    import { useLoadingStore } from '@/store/loadingStore';
    import Loader from '@/components/common/Loader.vue';
    import { RouterView } from 'vue-router';

    const loadingStore = useLoadingStore();
</script>
