<template>
    <app-page-tabs>
        <app-page-header title="Memorable Experiences" />

        <template #t>
            <LearnedExperienceGuideModalExplanation @done="navigationStore.setDefault" />
        </template>

        <template #ch>
            <UploadCurriculumGuideInline v-if="!profileStore.hasCurriculum" />
            <CareerHistoryGuide />

            <app-page-content>
                <template v-slot:intro>
                    <app-page-content-intro title="Career history">
                        Start by recording all your significant jobs you have had in your career,
                        with as much detail as you can.
                    </app-page-content-intro>
                </template>

                <CareerHistoryContent />
            </app-page-content>
        </template>

        <template #me>
            <LearnedExperienceGuide />

            <app-page-content>
                <template v-slot:intro>
                    <app-page-content-intro title="Your experiences">
                        Now reflect back on your most memorable experiences in those jobs. What
                        stood out to you as a learning moment? Describe what happened and then
                        honestly appraise how difficult or rewarding this memorable experience was.
                    </app-page-content-intro>
                </template>

                <v-row class="w-100" no-gutters v-if="true">
                    <LearnedExperienceSummary />
                </v-row>
                <LearnedExperienceContent />
            </app-page-content>
        </template>

        <app-page-tabs-footer v-if="navigationStore.item?.footer" />

        <PageActivity :area="UserActivityArea.LearnedExperiences" />
    </app-page-tabs>
</template>

<script setup lang="ts">
    import { onBeforeUnmount, onMounted, ref } from 'vue';
    import { useCanvasStore } from '@/store/canvas/store';
    import { UserActivityArea } from '@/api/types/userActivity';
    import PageActivity from '@/components/common/PageActivity.vue';
    import { useActivityStore } from '@/store/activity/store';
    import { useLearnedExperiencesStore } from '@/store/learned-experiences/store';
    import LearnedExperienceContent from '@/components/canvas/learned-experiences/LearnedExperienceContent.vue';
    import LearnedExperienceSummary from '@/components/canvas/learned-experiences/LearnedExperienceSummary.vue';
    import LearnedExperienceGuideModalExplanation from '@/components/canvas/learned-experiences/guide-modals/LearnedExperienceGuideModalExplanation.vue';
    import LearnedExperienceGuide from '@/components/canvas/learned-experiences/guide-modals/LearnedExperienceGuide.vue';
    import { storyRoute } from '@/router/routes';
    import { useNavigationStore } from '@/store/navigation/useNavigationStore';
    import CareerHistoryContent from '@/components/profile/career-history/CareerHistoryContent.vue';
    import UploadCurriculumGuideInline from '@/components/canvas/learned-experiences/UploadCurriculumGuideInline.vue';
    import CareerHistoryGuide from '@/components/profile/career-history/CareerHistoryGuide.vue';
    import { useUserProfileStore } from '@/store/profile/store';
    import { useUserJobStore } from '@/store/user-job/store';
    import { makeNavigationTabs } from '@/store/navigation/utils';
    import { useRoute } from 'vue-router';
    import AppPageTabsFooter from '@/components/common/app-page/AppPageTabsFooter.vue';
    import { makeNext, makePrevious } from '@/components/common/app-page/types';

    const route = useRoute();
    const items = makeNavigationTabs(
        [
            {
                title: 'Tutorial',
                key: 't',
                footer: false,
            },
            {
                title: 'Career History',
                key: 'ch',
                completed: () => userJobStore.hasUserJobs,
                footer: {
                    prev: makePrevious({
                        to: storyRoute,
                    }),
                    next: {},
                },
            },
            {
                title: 'Memorable Experiences',
                key: 'me',
                completed: () => store.isAreaComplete,
                default: true,
                footer: {
                    prev: {},
                    next: makeNext({
                        to: storyRoute,
                    }),
                },
            },
        ],
        route.query.tab as string,
    );

    const showReminder = ref(false);

    const activityStore = useActivityStore();
    const canvasStore = useCanvasStore();
    const userJobStore = useUserJobStore();
    const profileStore = useUserProfileStore();
    const store = useLearnedExperiencesStore();

    const navigationStore = useNavigationStore();
    navigationStore.init(items);
    const { setTutorial } = useNavigationStore();

    await userJobStore.load();
    await activityStore.load();
    await canvasStore.load();
    await store.load();

    await activityStore.addEntryActivity(UserActivityArea.LearnedExperiences);

    onMounted(() => {
        if (!store.hasLearnedExperiences) {
            setTutorial();
        } else if (!store.isAreaComplete) {
            showReminder.value = true;
        }
    });

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.LearnedExperiences);
    });
</script>
