<template>
    <app-page-header-desktop
        v-if="isDesktop"
        :hide-tutorial="hideTutorial"
        :section-title="sectionTitle"
        :title="title"
        :items="navigationStore.items"
    >
        <template #title>
            <slot name="title" />
        </template>
        <template #navigation>
            <AppPageHeaderDesktopNavigation :items="navigationStore.items" />
        </template>
    </app-page-header-desktop>
    <app-page-header-mobile
        v-else
        :hide-tutorial="hideTutorial"
        :section-title="sectionTitle"
        :title="title"
        @show-tutorial="setTutorial"
    >
        <template #title>
            <slot name="title" />
        </template>
    </app-page-header-mobile>
</template>

<script setup lang="ts">
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import AppPageHeaderDesktop from '@/components/common/app-page/AppPageHeaderDesktop.vue';
    import AppPageHeaderMobile from '@/components/common/app-page/AppPageHeaderMobile.vue';
    import AppPageHeaderDesktopNavigation from '@/components/common/app-page/AppPageHeaderDesktopNavigation.vue';
    import { useNavigationStore } from '@/store/navigation/useNavigationStore';

    const emit = defineEmits(['show-tutorial']);

    const navigationStore = useNavigationStore();
    const { setTutorial } = useNavigationStore();

    withDefaults(
        defineProps<{
            title?: string;
            sectionTitle?: string;
            hideTutorial?: boolean;
        }>(),
        {
            title: '',
        },
    );

    const { isDesktop } = useIsDesktop();
</script>
