<template>
    <app-page-tabs>
        <app-page-header title="Personality type" />

        <template #tutorial>
            <PersonalityTypeGuideModalExplanation @done="navigationStore.setDefault" />
        </template>
        <template #activity>
            <app-page-content>
                <template v-slot:intro>
                    <app-page-content-intro title="Let’s find out your type">
                        The Myers Briggs Type Indicator (MBTI) is based on the work with Carl Jung's
                        theory of personality types. Today, the MBTI inventory is one of the world's
                        most widely used psychological instruments, and reveals valuable data and
                        insights relevant to the workplace.
                        <br />
                        <app-external-button class="mt-5" href="https://www.16personalities.com/">
                            Read more about MBTI
                        </app-external-button>
                    </app-page-content-intro>
                </template>
                <PersonalityTypeContent />
            </app-page-content>
        </template>

        <app-page-footer v-if="!navigationStore.isTutorial">
            <template v-slot:errors>
                <SubmitNotification
                    :store="store"
                    :activity-area="UserActivityArea.PersonalityType"
                />
            </template>
            <template v-slot:actions>
                <app-page-footer-save-to-story-actions
                    @on-submit="onSaveClick"
                    submit-text="Continue"
                    :submit-loading="store.isUpdating"
                    :submit-disabled="!store.isDirty"
                />
                <WarningChangesWillBeLost
                    :value="store.isDirty"
                    @on-discard-changes="onDiscardChanges"
                    @on-submit="onSaveClick"
                />
            </template>
        </app-page-footer>

        <PageActivity :area="UserActivityArea.PersonalityType" />
    </app-page-tabs>
</template>

<script setup lang="ts">
    import { onBeforeUnmount, onMounted } from 'vue';
    import SubmitNotification from '@/components/footer-actions/SubmitNotification.vue';
    import WarningChangesWillBeLost from '@/components/footer-actions/WarningChangesWillBeLost.vue';
    import { UserActivityArea } from '@/api/types/userActivity';
    import PageActivity from '@/components/common/PageActivity.vue';
    import PersonalityTypeGuideModalExplanation from '@/components/canvas/personality-type/guide-modal/PersonalityTypeGuideModalExplanation.vue';
    import { useCanvasStore } from '@/store/canvas/store';
    import { useActivityStore } from '@/store/activity/store';
    import { usePersonalityTypeStore } from '@/store/personality-type/store';
    import PersonalityTypeContent from '@/components/canvas/personality-type/PersonalityTypeContent.vue';
    import { useRouter } from 'vue-router';
    import { storyRoute } from '@/router/routes';
    import { useNavigationStore } from '@/store/navigation/useNavigationStore';
    import { makeActivityTabs } from '@/store/navigation/utils';

    const canvasStore = useCanvasStore();
    const activityStore = useActivityStore();
    const store = usePersonalityTypeStore();
    const navigationStore = useNavigationStore();
    const { setTutorial } = useNavigationStore();

    const items = makeActivityTabs(() => store.isAreaComplete);
    navigationStore.init(items);

    await activityStore.load();
    await canvasStore.load();
    await store.load();

    await activityStore.addEntryActivity(UserActivityArea.PersonalityType);

    onMounted(() => {
        if (!store.original) {
            setTutorial();
        }
    });

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.PersonalityType);
    });

    const onDiscardChanges = async () => {
        // This is done to reset the store, so changes are discarded, so next time the data is loaded from scratch
        // This is only done on stores that are not save on change, and have an explicit save button
        store.$reset();
    };

    const router = useRouter();

    const onSaveClick = async () => {
        await store.save();

        await router.push(storyRoute);
    };
</script>

<style scoped lang="scss">
    .image-container {
        position: absolute;
        right: 30px;
        bottom: 10px;
    }
</style>
