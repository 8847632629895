<template>
    <v-menu
        v-model="menu"
        :close-on-content-click="true"
        location="end"
        class="cursor-pointer"
        offset="24"
    >
        <template v-slot:activator="{ props }">
            <v-list-item nav>
                <template #prepend>
                    <v-avatar
                        v-bind="props"
                        size="38"
                        class="ml-4"
                        color="custom-yellow"
                        :class="clickable ? 'clickable-avatar' : ''"
                    >
                        <span class="avatar-initials font-weight-600 text-custom-neutral-black">{{
                            displayUserInitials
                        }}</span>
                    </v-avatar>
                </template>
            </v-list-item>
        </template>

        <v-card min-width="234" max-width="334" class="pa-0 rounded-8" elevation="1" variant="outlined">
            <v-card-item class="pa-4 cursor-default">
                <template #title>
                    <div class="text-heading-h5 font-weight-600 text-black">
                        {{ userStore.displayName }}
                        <div class="text-paragraph-xs text-black">
                            {{ userStore.user?.email }}
                        </div>
                    </div>
                </template>
                <template #append>
                    <v-avatar size="38" color="custom-yellow" class="ml-4">
                        <span class="avatar-initials font-weight-600 text-custom-neutral-black">
                            {{ displayUserInitials }}
                        </span>
                    </v-avatar>
                </template>
            </v-card-item>

            <v-divider />

            <v-card-text class="pa-0 py-4">
                <v-list-item :to="profileRoute" class="font-weight-600 text-paragraph-sm">
                    Profile
                </v-list-item>
                <v-list-item @click="handleLogout" class="font-weight-600 text-paragraph-sm">
                    Logout
                </v-list-item>
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue';
    import { getUserInitials } from '@/store/user/util';
    import type { User } from '@/api/types/user';
    import { useRouter } from 'vue-router';
    import { profileRoute } from '@/router/routes';
    import { useAuth0 } from '@/auth/auth';
    import { useInlineGuideStore } from '@/store/inline-guide/store';
    import { makeLogoutHandle } from '@/components/utils';
    import { useUsersStore } from '@/store/user/store';

    const router = useRouter();

    const menu = ref(false);

    const props = defineProps<{
        user: User | null;
        /**
         * Whether the avatar should be clickable or not.
         * Note: I could not find an easy way of doing this in vuetify, so I added a custom class
         * and some styles
         */
        clickable: boolean;
    }>();

    const displayUserInitials = computed(() => {
        if (props.user) {
            return getUserInitials(props.user);
        } else {
            return 'You';
        }
    });

    const userStore = useUsersStore();

    const { logout } = useAuth0();
    const store = useInlineGuideStore();
    const handleLogout = async () => {
        const _logout = makeLogoutHandle(store, logout);
        await _logout();
    };
</script>

<style scoped lang="scss">
    .v-card {
        border: 1px solid rgb(var(--v-theme-outline));
    }

    .clickable-avatar {
        cursor: pointer;
    }

    .avatar-initials {
        font-size: 18px;
    }

    :deep(.v-list-item__overlay) {
        display: none;
    }
</style>
