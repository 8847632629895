<template>
    <v-container
        fluid
        class="bg-primary-light mt-0 mx-0 ma-0 rounded-16 d-print-none"
        :class="isDesktop ? 'px-10 py-8' : 'px-5 py-4'"
    >
        <v-row no-gutters class="ma-0 w-100">
            <StoryHeadingWelcomeCardProgress />
        </v-row>

        <v-row no-gutters class="mx-0 mb-1 w-100 align-center" v-if="activityStore.lastCompleted">
            <span class="mr-8">
                <span class="text-paragraph-sm text-custom-neutral-grey-700"> Last activity </span>
                <div class="text-paragraph-sm font-weight-600 w-100">
                    {{ lastCompletedDate }}
                </div>
            </span>
            <span class="">
                <span class="text-paragraph-sm text-custom-neutral-grey-700"> Last completed </span>
                <div class="text-paragraph-sm font-weight-600 w-100">
                    {{ lastCompletedActivityLabel }}
                </div>
            </span>
            <v-spacer />
            <app-button v-if="showPrintStory && isDesktop" @click="onPrintStory" class="mt-4">
                Print Story
            </app-button>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import StoryHeadingWelcomeCardProgress from '@/components/story/heading/StoryHeadingWelcomeCardProgress.vue';
    import { computed } from 'vue';
    import { useActivityStore } from '@/store/activity/store';
    import { useStoryStore } from '@/store/story/store';
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const activityStore = useActivityStore();
    const storyStore = useStoryStore();

    const lastCompletedDate = computed(() => {
        return activityStore.lastCompletedDate;
    });

    const lastCompletedActivityLabel = computed(() => {
        return activityStore.lastCompletedActivityLabel;
    });

    const { isDesktop } = useIsDesktop();

    const showPrintStory = computed(() => {
        return storyStore.story?.uvp;
    });

    const onPrintStory = () => {
        window.print();
    };
</script>
