import type {
    NavigationTabItem,
    NavigationTabItemConfig,
} from '@/components/common/app-page/types';

/**
 * Make navigation tabs from items by optionally overriding the default key
 * @param items
 * @param overrideDefaultKey
 */
export const makeNavigationTabs = (
    items: Partial<NavigationTabItemConfig>[],
    overrideDefaultKey?: string | null,
): NavigationTabItem[] => {
    return items.map((i, index: number) => {
        const item = { ...i } as Partial<NavigationTabItem>;
        item.order = index;
        if (overrideDefaultKey) {
            item.default = item.key === overrideDefaultKey;
        }

        if (item.footer === false) {
            item.footer = undefined;
        }

        return item as NavigationTabItem;
    });
};

/**
 * Helper function to create tabs in each view,
 * as most activities are two tabs: tutorial & activity
 *
 * @param activityCompletedCheck: a function that returns if the activity is completed or not
 */
export const makeActivityTabs = (activityCompletedCheck: () => boolean): NavigationTabItem[] => {
    return makeNavigationTabs([
        {
            title: 'Tutorial',
            key: 'tutorial',
        },
        {
            title: 'Activity',
            key: 'activity',
            completed: activityCompletedCheck,
            default: true,
        },
    ]);
};
