<template>
    <div class="text-heading-h3 w-100">
        Welcome back {{ userStore.firstName }} , your story is {{ progress.toFixed() }}% complete!
    </div>

    <v-progress-linear
        :model-value="progress"
        color="secondary-orange"
        height="6"
        :rounded="true"
        class="w-100 d-print-none my-4"
    ></v-progress-linear>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { useStoryStore } from '@/store/story/store';

    import { type StoryActivity, StoryActivityStatus } from '@/store/story/types';
    import { useUsersStore } from '@/store/user/store';

    const store = useStoryStore();
    const userStore = useUsersStore();

    const progress = computed(() => {
        const total = store.storyActivities.length;
        const completedItems = store.storyActivities.filter((item: StoryActivity) => {
            return item.status === StoryActivityStatus.Completed;
        });

        return (completedItems.length * 100) / total;
    });
</script>
