<template>
    <app-page-footer>
        <template v-slot:actions>
            <CanvasSaveProgressActions
                :close-text="prev?.text ?? 'Back'"
                @on-back="onPrevious"
                :submit-hide="!next"
                :submit-disabled="next?.disabled ? next.disabled() : false"
                :submit-loading="next?.loading ? next.loading() : false"
                :submit-tooltip="next?.tooltip"
                :title="next?.text ?? 'Continue'"
                @on-submit="onNext"
            />
            <v-spacer />
        </template>
    </app-page-footer>
</template>

<script setup lang="ts">
    import { useNavigationStore } from '@/store/navigation/useNavigationStore';
    import CanvasSaveProgressActions from '@/components/canvas/CanvasSaveProgressActions.vue';
    import { computed } from 'vue';
    import { useRouter } from 'vue-router';

    const navigationStore = useNavigationStore();

    const footer = computed(() => {
        return navigationStore.item?.footer ?? null;
    });

    const next = computed(() => {
        return footer.value?.next ?? null;
    });

    const prev = computed(() => {
        return footer.value?.prev ?? null;
    });

    const router = useRouter();

    const onPrevious = () => {
        if (prev?.value?.to) {
            return router.push(prev.value.to);
        }

        navigationStore.setPrevious();
    };

    const onNext = () => {
        if (next.value?.to) {
            return router.push(next.value.to);
        }

        navigationStore.setNext();
    };
</script>
