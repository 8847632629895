<template>
    <v-menu v-model="menu" :close-on-content-click="true" location="end" class="cursor-pointer">
        <template v-slot:activator="{ props }">
            <AppHeaderListItem
                v-bind="props"
                class="ml-6 pa-0"
                :disabled="!userStore.hasProfileBeenSet"
                icon="question"
            />
        </template>

        <v-card
            min-width="234"
            max-width="334"
            class="pa-0 rounded-8"
            elevation="1"
            variant="outlined"
        >
            <v-card-item class="pa-4">
                <template #title>
                    <div class="text-heading-h5 text-black font-weight-600 cursor-default">
                        Support
                    </div>
                </template>
            </v-card-item>

            <v-divider />

            <v-card-text class="pa-0 py-4">
                <v-list-item
                    href="mailto:support@actvo.ai"
                    class="text-paragraph-sm text-black font-weight-600"
                >
                    Contact Us
                </v-list-item>
                <v-list-item
                    :to="privacyRoute"
                    class="text-paragraph-sm text-black font-weight-600 my-2"
                >
                    Privacy Policy
                </v-list-item>
                <v-list-item :to="termsRoute" class="text-paragraph-sm text-black font-weight-600">
                    Terms and Conditions
                </v-list-item>
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { challengePathRoute, privacyRoute, profileRoute, termsRoute } from '@/router/routes';
    import { useUsersStore } from '@/store/user/store';
    import AppHeaderListItem from '@/components/header/AppHeaderListItem.vue';

    const menu = ref(false);

    const userStore = useUsersStore();
</script>

<style scoped lang="scss">
    .v-card {
        border: 1px solid rgb(var(--v-theme-outline));
    }

    :deep(.v-list-item__overlay) {
        display: none;
    }
</style>
