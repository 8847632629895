<template>
    <SaveProgressButtons
        close-text="Back"
        :close-to="storyRoute"
        :submit-text="props.title ?? 'Continue'"
        :submit-disabled="props.submitDisabled"
        :submit-tooltip="props.submitTooltip"
        :submit-hide="props.submitHide"
        :submit-loading="props.submitLoading"
        :submit-to="props.submitTo"
        @on-submit="onSubmit"
        :full-width="fullWidth"
    />
</template>
<script setup lang="ts">
    import SaveProgressButtons from '@/components/footer-actions/SaveProgressButtons.vue';
    import { storyRoute } from '@/router/routes';
    import { type RouteLocationRaw, useRouter } from 'vue-router';

    const props = withDefaults(
        defineProps<{
            /**
             * If true, the component will not emit the submit event, but will navigate to the story route.
             * This is to compensate for the fact that the component is used in a context where the submit event is not handled.
             * Some activities have an auto-save feature that saves the data on each input (e.g.: skills, values, etc)
             */
            dummy?: boolean;

            title?: string;
            submitDisabled?: boolean;
            submitTooltip?: string;
            submitHide?: boolean;
            submitLoading?: boolean;
            submitTo?: RouteLocationRaw;
            fullWidth?: boolean;
        }>(),
        { dummy: false },
    );

    const emit = defineEmits(['on-submit']);
    const router = useRouter();

    const onSubmit = async () => {
        if (props.dummy) {
            await router.push(storyRoute);
        } else {
            emit('on-submit');
        }
    };
</script>
