<template>
    <v-btn
        :height="responsiveHeight"
        :color="color"
        :max-width="maxWidth"
        :slim="false"
        :to="props.to"
        :prepend-icon="prependIcon"
        :append-icon="appendIcon"
        :disabled="disabled"
        :loading="loading"
        @click="emit('click')"
        class="text-none opacity-1"
        :variant="variant"
        :class="cssClass"
        :size="size"
    >
        <slot></slot>
    </v-btn>
</template>

<script setup lang="ts">
    import type { RouteLocationRaw } from 'vue-router';
    import { computed } from 'vue';
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const emit = defineEmits(['click']);

    const props = withDefaults(
        defineProps<{
            hideCursor?: boolean;
            height?: number | string;
            color?: string;
            to?: RouteLocationRaw;
            prependIcon?: string;
            appendIcon?: string;
            disabled?: boolean;
            loading?: boolean;
            maxWidth?: number | string;
            variant?: NonNullable<'outlined' | 'flat' | 'elevated' | 'tonal' | 'plain'> | undefined;
            size?: NonNullable<'x-small' | 'small' | 'default' | 'large' | 'x-large'> | undefined;
            weight?: NonNullable<
                | 'light'
                | 'regular'
                | 'medium'
                | 'bold'
                | 'black'
                | 'thin'
                | '600'
                | '700'
                | undefined
            >;
            borderColor?:
                | 'neutral-black'
                | 'secondary-blue'
                | 'secondary-pink'
                | 'secondary-purple';
            overrideCss?: string;
        }>(),
        {
            variant: 'outlined',
            size: 'default',
            weight: '600',
            maxWidth: '100%',
            overrideCss: '',
            hideCursor: false
        },
    );

    const { isDesktop, isMobile, responsiveClass } = useIsDesktop();

    const cssClass = computed(() => {
        return {
            [`font-weight-${props.weight}`]: props.weight,
            [`border-color-${props.borderColor}`]: props.borderColor,
            [props.overrideCss]: !!props.overrideCss,
            ['cursor-not-allowed']: props.disabled,
            ['cursor-pointer']: !props.disabled,
            [responsiveClass.value]: true,
        };
    });

    const responsiveHeight = computed(() => {
        if (props.height) {
            return props.height
        }

        if (isDesktop.value) {
            return 38
        }

        if (isMobile.value) {
            return 31
        }


    });
</script>

<style scoped lang="scss">
    a.v-btn,
    button {
        line-height: 24px;
        letter-spacing: normal;
    }

    .text-white {
        --border-color: white;
    }

    .border-color-neutral-black {
        --border-color: rgb(var(--v-theme-custom-neutral-black));
    }

    .border-color-secondary-blue {
        --border-color: rgb(var(--v-theme-secondary-blue));
    }

    .border-color-secondary-pink {
        --border-color: rgb(var(--v-theme-secondary-pink));
    }

    .border-color-secondary-purple {
        --border-color: rgb(var(--v-theme-secondary-purple));
    }

    button.v-btn--variant-outlined {
        border: 1px solid var(--border-color, rgb(var(--v-theme-custom-neutral-black))) !important;
    }


    .desktop {
        :deep(.v-btn__content) {
            //styleName: D/Paragraph/SM Bold;
            // This should be mixin but don't know how to do it at the moment see styles/global/@typescript_mixin.scss
            font-family: Figtree;
            font-size: 14px;
            font-weight: 600;
            line-height: 22.4px;
            letter-spacing: -0.0125em;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;

        }
    }

    .mobile {
        :deep(.v-btn__content) {
            // styleName: D/Paragraph/SM Bold;
            // TODO CORE-257
            // This should be mixin but don't know how to do it at the moment see styles/global/@typescript_mixin.scss
            font-family: Figtree;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 160%; /* 19.2px */
            letter-spacing: -0.15px;

        }
    }
</style>
